import React from "react";
import styles from "../../pages/onepage/styles.js";
import "../../pages/onepage/styles.css";
import Atuacao from "../../components/Atuacao/index.js";
import ContactForm from "../../components/contato.js";
import { FaPhone, FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";
import quemSomos1 from "../../assets/quemSomos1.webp";
import quemSomos2 from "../../assets/quemSomos2.webp";
import quemSomos3 from "../../assets/quemSomos3.webp";
import profileChaves from "../../assets/profileChaves.webp";
import profileDante from "../../assets/profileDante.webp";

const ConteudoDesktop = ({ handleClick, sectionRef }) => {
  const handleContactClick = (event) => {
    event.preventDefault();
    if (handleClick) {
      handleClick(event);
    }
  };
  return (
    <div>
      <div style={styles.containerFlex}>
        <div style={styles.background}>
          <div id="home" style={styles.containerHome}>
            <div style={styles.containerIcon}>
              <div style={styles.borderRHome}></div>
              <div style={styles.iconsL}>
                <a target="_blank" href="tel:43991723978" style={styles.icons}>
                  <FaPhone />
                </a>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=43991723978"
                  style={styles.icons}
                >
                  <FaWhatsapp />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/granoechavesadvogados.direitodasaude/"
                  style={styles.icons}
                >
                  <FaFacebook />
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/granoechavesadvogados/"
                  style={styles.icon}
                >
                  <FaInstagram />
                </a>
              </div>
              <div style={styles.borderLHome}></div>
            </div>

            <div style={styles.centeredDivE}>
              <h1 style={styles.H1Tittle}>Grano e Chaves advogados</h1>
              <p style={styles.pTittle}>
                Ajudando a salvar vidas através do Direito.
              </p>

              <a href="" onClick={handleContactClick} style={styles.bTittle}>
                ENTRE EM CONTATO
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="atuacao">
        <div style={styles.centeredDiv}>
          <h2 style={styles.h2TittleArea}>ÁREAS DE ATUAÇÃO</h2>
          <p style={styles.divAtuacaoText}>
            Nossos advogados são especialistas em diversas áreas do direito, com
            um enfoque específico em Direito Médico e Direito da Saúde. Conheça
            nossas principais áreas de atuação:
          </p>

          <div>
            <Atuacao />
          </div>

          <div style={styles.backgroundJustica}>
            <div style={styles.containerAtendimento}>
              <div style={styles.containerTextJustica}>
                <h3 style={styles.h3TextAtendimento}>
                  Advogados Especializados em Direito da Saúde e Direito Médico:
                  Protegendo seus Direitos.
                </h3>
                <p style={styles.pTextAtendimento}>
                  Somos uma equipe de advogados especializados em Direito da
                  Saúde e Direito Médico, comprometidos em defender os direitos
                  dos pacientes. Com sede em Londrina, Paraná, oferecemos
                  serviços de representação jurídica em todo o Brasil, com
                  ênfase em ações contra planos de saúde, o SUS e questões
                  relacionadas à negligência médica.
                </p>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=43991723978"
                  style={styles.bAtendimento}
                >
                  QUERO SABER MAIS
                </a>
              </div>
            </div>
          </div>

          <div style={styles.backgroundAcoes}>
            <div style={styles.containerTextCompromisso}>
              <h3 style={styles.h3TextCompromisso}>
                Ações contra Planos de Saúde: Garantindo sua Cobertura e
                Direitos
              </h3>
              <p style={styles.pTextCompromisso}>
                Como especialistas em Direito da Saúde, ajudamos pacientes a
                enfrentar questões complexas envolvendo planos de saúde. Lidamos
                com negativas de cobertura de tratamentos e procedimentos,
                atrasos no atendimento, reajustes abusivos de mensalidades e
                cancelamentos indevidos durante o tratamento. Nossa equipe está
                preparada para lutar pelos seus direitos e garantir que você
                receba a assistência médica necessária.
              </p>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=43991723978"
                style={styles.bAtendimentoCompromisso}
              >
                AGENDE CONOSCO
              </a>
            </div>
          </div>

          <div style={styles.backgroundMedico}>
            <div style={styles.containerAtendimento}>
              <div style={styles.containerTextJustica}>
                <h3 style={styles.h3TextAtendimento}>
                  Defesa dos seus Direitos no SUS: Buscando uma Saúde Pública de
                  Qualidade
                </h3>
                <p style={styles.pTextAtendimento}>
                  Representamos pacientes em ações contra o SUS, buscando
                  garantir o acesso adequado aos serviços de saúde. Lutamos
                  contra a falta de atendimento, demoras no agendamento de
                  consultas e cirurgias, negativas de fornecimento de
                  medicamentos e outras questões. Nosso objetivo é assegurar que
                  você receba o tratamento adequado e a qualidade de cuidados
                  que merece.
                </p>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=43991723978"
                  style={styles.bAtendimento}
                >
                  SAIBA MAIS
                </a>
              </div>
            </div>
          </div>

          <div style={styles.backgroundMaos}>
            <div style={styles.containerTextCompromisso}>
              <h3 style={styles.h3TextCompromisso}>
                Direito Médico: Proteção contra Negligência e Erro Médico
              </h3>
              <p style={styles.pTextCompromisso}>
                Em nossa atuação em Direito Médico, estamos preparados para
                representar clientes em casos de negligência médica, erro médico
                e responsabilidade civil de hospitais e profissionais de saúde.
                Buscamos justiça em situações de má prática médica, defendendo
                os direitos dos pacientes e buscando a devida compensação pelos
                danos sofridos.
              </p>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=43991723978"
                style={styles.bAtendimentoCompromisso}
              >
                ENTRE EM CONTATO
              </a>
            </div>
          </div>

          <div style={styles.backgroundDante}>
            <div style={styles.containerAtendimento}>
              <div style={styles.containerTextDante}>
                <h3 style={styles.h3TextAtendimento}>
                  Atendimento Personalizado: Online e Presencial em Londrina
                </h3>
                <p style={styles.pTextAtendimento}>
                  Valorizamos a comodidade e a acessibilidade nos serviços
                  jurídicos. Oferecemos atendimento personalizado 100% online,
                  permitindo a assinatura de documentos e reuniões virtuais.
                  Além disso, você também pode contar com nosso atendimento
                  presencial em nossa moderna sede em Londrina-PR. Nossas
                  instalações são projetadas para proporcionar conforto e
                  acessibilidade aos nossos clientes.
                </p>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=43991723978"
                  style={styles.bAtendimento}
                >
                  AGENDE AGORA
                </a>
              </div>
            </div>
          </div>

          <div style={styles.backgroundCompromisso}>
            <div style={styles.containerTextCompromisso}>
              <h3 style={styles.h3TextCompromisso}>
                Compromisso com a Ética e Transparência
              </h3>
              <p style={styles.pTextCompromisso}>
                Nosso trabalho é pautado pela ética e transparência.
                Comprometemo-nos a fornecer a representação jurídica que você
                merece, com integridade e dedicação. Ao entrar em contato
                conosco, você terá a tranquilidade de estar lidando com uma
                equipe confiável e comprometida com seus interesses. Agende uma
                consulta hoje mesmo para garantir a defesa de seus direitos
                relacionados ao Direito da Saúde e Direito Médico. Nossa equipe
                de advogados especializados está pronta para ajudá-lo.
              </p>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=43991723978"
                style={styles.bAtendimentoCompromisso}
              >
                AGENDE AGORA
              </a>
            </div>
          </div>

          <div id="equipe" className="containerBackgroundAbout">
            <fieldset className="fieldset">
              <div className="containerAbout">
                <legend className="legend">
                  <h2 className="h2Legend">Quem somos</h2>
                </legend>

                <div className="displayContainerEquipe">
                  <p className="pTextEquipe1">
                    Olá! Nós, Clito Dante J. Grano e André Chaves, somos os
                    fundadores do escritório de advocacia Grano e Chaves. Nos
                    conhecemos durante a graduação na Universidade Estadual de
                    Londrina em 2010 e, com mais de dez anos de experiência na
                    advocacia em áreas distintas, decidimos unir nossas forças
                    para oferecer serviços jurídicos especializados nas áreas de
                    Direito da Saúde e Direito Médico.
                  </p>
                  <div>
                    <img src={quemSomos1} alt="" className="imgEquipe1" />
                  </div>
                </div>

                <div className="displayContainerEquipe">
                  <div>
                    <img src={quemSomos2} alt="" className="imgEquipe2" />
                  </div>
                  <div>
                    <p className="pTextEquipe2">
                      O que nos motivou a formar essa parceria foi testemunhar
                      familiares e amigos sofrendo violações de direitos no
                      acesso a tratamentos por parte dos Planos de Saúde e do
                      SUS. Essas experiências despertaram em nós a vontade de
                      atuar de forma mais direcionada, garantindo que os
                      pacientes tenham seus direitos assegurados e que recebam o
                      tratamento adequado e justo.
                    </p>
                    <p className="pTextEquipe2">
                      Assim, combinamos nossa expertise com a de outros
                      profissionais qualificados, e formamos uma equipe de
                      advogados talentosos e dedicados, garantindo um
                      atendimento abrangente e personalizado para os nossos
                      clientes.
                    </p>
                  </div>
                </div>

                <div className="displayContainerEquipe">
                  <div>
                    <p className="pTextEquipe1">
                      Nossa estrutura para atendimento é totalmente acessível
                      para pessoas com deficiência, inclusive, podendo optar por
                      atendimento 100% digital. Com o slogan Ajudando a salvar
                      vidas através do Direito, buscamos transmitir a nossa
                      missão de fortalecer e representar os interesses dos
                      pacientes, assegurando que seus direitos sejam respeitados
                      em questões relacionadas à saúde.
                    </p>
                    <p className="pTextEquipe1">
                      Estamos comprometidos em oferecer um serviço jurídico de
                      excelência, com base em nossa expertise e na dedicação de
                      nossa equipe. Entre em contato conosco para agendar uma
                      consulta e contar com o suporte especializado do
                      escritório Grano e Chaves Advogados.
                    </p>
                  </div>

                  <div>
                    <img src={quemSomos3} alt="" className="imgEquipe1" />
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div>
            <div style={styles}>
              <div style={styles.containerProfile}>
                <div className="displayContainerProfile">
                  <div>
                    <img src={profileDante} alt="" className="imgProfile" />
                  </div>
                  <div className="textProfile">
                    <h3 style={styles.h3TextAtendimento}>
                      Clito Dante Eugênio Juliani Grano
                    </h3>

                    <ul className="ulProfile">
                      <li>
                        Advogado com inscrição principal na OAB/PR sob o n.º
                        74604.
                      </li>
                      <li>
                        Pós-graduado em Direito do Trabalho e Direito
                        Previdenciário pela Universidade Estadual do Norte do
                        Paraná - UENP.
                      </li>
                      <li>
                        Pós-graduando em Direito Administrativo pela
                        Universidade Cândido Mendes.
                      </li>
                      <li>
                        Bacharel em Direito pela Universidade Estadual de
                        Londrina - UEL.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="displayContainerProfile">
                  <div>
                    <img src={profileChaves} alt="" className="imgProfile" />
                  </div>

                  <div className="textProfile">
                    <h3 style={styles.h3TextAtendimento}>
                      André Luiz de Souza Chaves
                    </h3>

                    <ul className="ulProfile">
                      <li>
                        Advogado com inscrição principal na OAB/PR sob o n.º
                        75556.
                      </li>
                      <li>
                        Pós-graduado em Neurociência Aplicada ao Direito e
                        Comportamento Humano pela Escola da Magistratura Federal
                        - ESMAFE.
                      </li>
                      <li>
                        Bacharel em Direito pela Universidade Estadual de
                        Londrina - UEL.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="faleconosco" style={styles.contactBackground}>
            <div style={styles.containerContact}>
              <div style={styles.textContact}>
                <h2 style={styles.h2TextContact}>
                  Seus direitos merecem ser protegidos. Fale Conosco.
                </h2>
                <p>
                  Deixe-nos cuidar do seu caso e garantir que a justiça seja
                  feita.
                </p>
              </div>
              <div style={styles.contentContact}>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConteudoDesktop;
