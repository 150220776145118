import React, { useState, useRef } from "react";
import "./styles.css";
import idfale from "../../pages/onepage/index#faleconosco";
import zIndex from "@material-ui/core/styles/zIndex";

const Atuacao = () => {
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const cardRefs = useRef([]);

  const handleCardClick = (index) => {
    if (expandedCardIndex === index) {
      setExpandedCardIndex(null);
    } else {
      setExpandedCardIndex(index);
      if (index === 7) {
        window.location.href = "../../pages/onepage/index#sectionRef";
      } else {
        cardRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  };

  const handleScrollToSection = () => {
    const faleConoscoSection = document.getElementById("sectionRef");
    faleConoscoSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="containerDivCard">
        <div id="containerLeft">
          <div
            className={`containerCard ${
              expandedCardIndex === 0 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(0)}
            ref={(el) => (cardRefs.current[0] = el)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                id="scale-balance"
                d="M16.2 3.5C15.2 2.5 13.9 2 12.7 2S10.1 2.5 9.2 3.5L3.4 9.1C1.4 11.1 1.4 14.2 3.4 16.2S8.5 18.2 10.5 16.2L16.2 10.5C18.1 8.6 18.1 5.4 16.2 3.5M14.8 9.1L12 11.9L8.4 8.4L4 12.8C4 12 4.2 11.1 4.9 10.5L10.6 4.8C11.1 4.3 11.9 4 12.6 4S14.1 4.3 14.7 4.8C15.9 6.1 15.9 7.9 14.8 9.1M19.6 7.1C19.6 7.9 19.4 8.6 19.2 9.4C20.2 10.6 20.2 12.4 19.1 13.5L16.3 16.3L14.8 14.8L12 17.6C10.7 18.9 8.9 19.6 7.2 19.6C7.4 19.9 7.6 20.2 7.9 20.5C9.9 22.5 13 22.5 15 20.5L20.7 14.8C22.7 12.8 22.7 9.7 20.7 7.7C20.2 7.5 19.9 7.3 19.6 7.1Z"
                transform="translate(-2 -3)"
                fill="#cf9426"
              />
            </svg>
            <h4 className="h4Card">Medicamento de Alto Custo</h4>
            {expandedCardIndex === 0 && (
              <p className="cardContent">
                Garantimos acesso a tratamentos médicos de alto custo por meio
                de ação judicial. Planos de Saúde ou SUS são condenados a
                fornecer o medicamento prescrito gratuitamente por seu médico
                para sua recuperação. Asseguramos seus direitos em saúde.
              </p>
            )}
          </div>

          <div
            className={`containerCard ${
              expandedCardIndex === 1 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(1)}
            ref={(el) => (cardRefs.current[1] = el)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              width="78.75"
              height="70.777"
              viewBox="0 0 78.75 70.777"
            >
              <path
                id="medical-bag"
                d="M33.5,3l-7.864,7.864v7.864h-11.8c-4.522,0-7.392,3.932-7.864,7.864L2.041,65.913c-.472,3.932,2.123,7.864,7.864,7.864H72.818c5.741,0,8.336-3.932,7.864-7.864L76.75,26.592c-.472-3.932-3.7-7.864-7.864-7.864H57.09V10.864L49.226,3H33.5m0,7.864H49.226v7.864H33.5V10.864m3.932,19.66h7.864v11.8h11.8v7.864h-11.8v11.8H37.429v-11.8h-11.8V42.321h11.8Z"
                transform="translate(-1.986 -3)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Medicamento com Canabidiol – CBD</h4>
            {expandedCardIndex === 1 && (
              <p className="cardContent">
                Apesar dos avanços na medicina, pacientes enfrentam barreiras
                para obter medicamentos com Canabidiol (CBD). Lutamos legalmente
                para garantir acesso gratuito ao CBD prescrito por seu médico,
                restaurando sua saúde.
              </p>
            )}
          </div>

          <div
            className={`containerCard ${
              expandedCardIndex === 2 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(2)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M12.75,3.94C13.75,3.22 14.91,2.86 16.22,2.86C16.94,2.86 17.73,3.05 18.59,3.45C19.45,3.84 20.13,4.3 20.63,4.83C21.66,6.11 22.09,7.6 21.94,9.3C21.78,11 21.22,12.33 20.25,13.27L12.66,20.86C12.47,21.05 12.23,21.14 11.95,21.14C11.67,21.14 11.44,21.05 11.25,20.86C11.06,20.67 10.97,20.44 10.97,20.16C10.97,19.88 11.06,19.64 11.25,19.45L15.84,14.86C16.09,14.64 16.09,14.41 15.84,14.16C15.59,13.91 15.36,13.91 15.14,14.16L10.55,18.75C10.36,18.94 10.13,19.03 9.84,19.03C9.56,19.03 9.33,18.94 9.14,18.75C8.95,18.56 8.86,18.33 8.86,18.05C8.86,17.77 8.95,17.53 9.14,17.34L13.73,12.75C14,12.5 14,12.25 13.73,12C13.5,11.75 13.28,11.75 13.03,12L8.44,16.64C8.25,16.83 8,16.92 7.73,16.92C7.45,16.92 7.21,16.83 7,16.64C6.8,16.45 6.7,16.22 6.7,15.94C6.7,15.66 6.81,15.41 7.03,15.19L11.63,10.59C11.88,10.34 11.88,10.11 11.63,9.89C11.38,9.67 11.14,9.67 10.92,9.89L6.28,14.5C6.06,14.7 5.83,14.81 5.58,14.81C5.3,14.81 5.06,14.71 4.88,14.5C4.69,14.3 4.59,14.06 4.59,13.78C4.59,13.5 4.69,13.27 4.88,13.08C7.94,10 9.83,8.14 10.55,7.45L14.11,10.97C14.5,11.34 14.95,11.53 15.5,11.53C16.2,11.53 16.75,11.25 17.16,10.69C17.44,10.28 17.54,9.83 17.46,9.33C17.38,8.83 17.17,8.41 16.83,8.06L12.75,3.94M14.81,10.27L10.55,6L3.47,13.08C2.63,12.23 2.15,10.93 2.04,9.16C1.93,7.4 2.41,5.87 3.47,4.59C4.66,3.41 6.08,2.81 7.73,2.81C9.39,2.81 10.8,3.41 11.95,4.59L16.22,8.86C16.41,9.05 16.5,9.28 16.5,9.56C16.5,9.84 16.41,10.08 16.22,10.27C16.03,10.45 15.8,10.55 15.5,10.55C15.23,10.55 15,10.45 14.81,10.27V10.27Z"
                transform="translate(-2 -3)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Tratamento Oncológico</h4>
            {expandedCardIndex === 2 && (
              <p className="cardContent">
                Garantimos seu direito na batalha contra o câncer. Oferecemos
                acesso a exames e tratamentos, como PET SCAN, e medicamentos de
                alto custo, como Herceptin e Imbruvica, frequentemente negados
                pelo SUS e Planos de Saúde. Lutamos por sua saúde gratuitamente
                e de forma duradoura.
              </p>
            )}
          </div>

          <div
            className={`containerCard ${
              expandedCardIndex === 3 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(3)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 17Q20.86 17 21.45 17.6T22.03 19L14 22L7 20V11H8.95L16.22 13.69Q17 14 17 14.81 17 15.28 16.66 15.63T15.8 16H13L11.25 15.33L10.92 16.27L13 17H20M16 3.23Q17.06 2 18.7 2 20.06 2 21 3T22 5.3Q22 6.33 21 7.76T19.03 10.15 16 13Q13.92 11.11 12.94 10.15T10.97 7.76 10 5.3Q10 3.94 10.97 3T13.31 2Q14.91 2 16 3.23M.984 11H5V22H.984V11Z"
                transform="translate(-3 -3)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Tratamento para Autismo</h4>
            {expandedCardIndex === 3 && (
              <p className="cardContent">
                O STJ garantiu ampla cobertura para o tratamento de TEA. Na
                Grano e Chaves Advogados, lutamos por você, assegurando acesso
                ao melhor tratamento, incluindo Musicoterapia, Equinoterapia e
                Canabidiol (CBD), sem limitação de sessões pelo Plano de Saúde.
              </p>
            )}
          </div>
        </div>

        <div id="containerRight">
          <div
            className={`containerCard ${
              expandedCardIndex === 4 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(4)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              width="78.75"
              height="78.75"
              viewBox="0 0 78.75 78.75"
            >
              <path
                id="hospital-box"
                d="M68.625,51.125h-17.5v17.5h-17.5v-17.5h-17.5v-17.5h17.5v-17.5h17.5v17.5h17.5M73,3H11.75A8.72,8.72,0,0,0,3,11.75V73a8.75,8.75,0,0,0,8.75,8.75H73A8.75,8.75,0,0,0,81.75,73V11.75A8.748,8.748,0,0,0,73,3Z"
                transform="translate(-3 -3)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Bariátrica e plástica</h4>
            {expandedCardIndex === 4 && (
              <p className="cardContent">
                Lutamos por pacientes com obesidade, enfrentando a negativa dos
                Planos de Saúde. Garantimos acesso à cirurgia bariátrica e
                plástica reparadora por meio de ação judicial, para que você
                recupere sua saúde física e também emocional. Seus direitos de
                saúde são nossa prioridade.
              </p>
            )}
          </div>

          <div
            className={`containerCard ${
              expandedCardIndex === 5 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(5)}
          >
            <svg
              className="svgContainer"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27 18.039L16 9.501 5 18.039V14.56l11-8.54 11 8.538v3.481zm-2.75-.31v8.251h-5.5v-5.5h-5.5v5.5h-5.5v-8.25L16 11.543l8.25 6.186z"
                transform="translate(-3 -3)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Home Care</h4>
            {expandedCardIndex === 5 && (
              <p className="cardContent">
                Planos de Saúde frequentemente negam tratamento fora do hospital
                por motivos financeiros, desrespeitando o Direito Brasileiro. O
                médico decide o tratamento, incluindo assistência domiciliar
                (home care). Lutamos legalmente para que o Plano de Saúde cubra
                todos os custos do tratamento home care, mesmo que o contrato
                particular diga o contrário.
              </p>
            )}
          </div>

          <div
            className={`containerCard cardFamilia ${
              expandedCardIndex === 6 ? "expanded" : ""
            }`}
            onClick={() => handleCardClick(6)}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              width="78.75"
              height="77.214"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 23.3L18.4 22.8C16.4 20.9 15 19.7 15 18.2C15 17 16 16 17.2 16C17.9 16 18.6 16.3 19 16.8C19.4 16.3 20.1 16 20.8 16C22 16 23 16.9 23 18.2C23 19.7 21.6 20.9 19.6 22.8L19 23.3M3 7V5H5V4C5 2.89 5.9 2 7 2H13V9L15.5 7.5L18 9V2H19C20.05 2 21 2.95 21 4V13.34C20.37 13.12 19.7 13 19 13C15.69 13 13 15.69 13 19C13 20.09 13.29 21.12 13.8 22H7C5.95 22 5 21.05 5 20V19H3V17H5V13H3V11H5V7H3M5 5V7H7V5H5M5 19H7V17H5V19M5 13H7V11H5V13Z"
                transform="translate(-2 -2)"
                fill="#cf9426"
              />
            </svg>

            <h4 className="h4Card">Recusas hospitalares</h4>
            {expandedCardIndex === 6 && (
              <p className="cardContent">
                É proibido ao Plano de Saúde negar a cobertura de exames e
                tratamentos hospitalares recomendados pelo médico. Em casos
                urgentes, buscamos uma medida liminar para garantir seu rápido
                acesso aos procedimentos necessários para restaurar sua saúde.
              </p>
            )}
          </div>

          <div
            className={`containerCardActive ${
              expandedCardIndex === 7 ? "expanded" : ""
            }`}
            onClick={handleScrollToSection}
          >
            <svg
              className="svgContainer"
              xmlns="http://www.w3.org/2000/svg"
              width="70.935"
              height="70.935"
              viewBox="0 0 70.935 70.935"
            >
              <path
                id="plus-box-multiple"
                d="M62.294,33.921H48.107V48.107H41.014V33.921H26.827V26.827H41.014V12.64h7.093V26.827H62.294M65.841,2H23.28a7.093,7.093,0,0,0-7.093,7.093V51.654a7.093,7.093,0,0,0,7.093,7.093H65.841a7.093,7.093,0,0,0,7.093-7.093V9.093A7.093,7.093,0,0,0,65.841,2M9.093,16.187H2V65.841a7.093,7.093,0,0,0,7.093,7.093H58.748V65.841H9.093Z"
                transform="translate(-2 -2)"
                fill="#001c34"
              />
            </svg>

            <div>
              <h4 className="h4Card"> Outras Áreas</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Atuacao;
