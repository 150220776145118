import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instagram from "../../assets/instagram.svg";
import whatsapp from "../../assets/whatsapp.svg";
import facebook from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";

const Sidebar = ({ blogs, filteredBlogs, setFilteredBlogs }) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (blogs && blogs.length > 0) {
      setFilteredBlogs(
        blogs.filter((blog) =>
          blog.attributes.Title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, blogs, setFilteredBlogs]);
  return (
    <div className="sidebarContainer">
      <div className="sidebar">
        <div className="sidebar-item">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <svg
              className="search-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>
          <h2>Categorias</h2>
          {filteredBlogs && filteredBlogs.length > 0 && (
            <ul>
              {Array.from(
                new Set(
                  filteredBlogs.map(
                    (blog) => blog?.attributes && blog?.attributes?.Category?.data?.attributes?.Category
                  )
                )
              ).map((category, index) => (
                <li key={index}>
                  <a href="#">{category}</a>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="sidebar-item">
          <h2>Posts Recentes</h2>
          {filteredBlogs && filteredBlogs.length > 0 && (
            <ul>
              {filteredBlogs.slice(0, 3).map((blog) => (
                <li key={blog.id}>
                  <Link to={`/${blog?.attributes?.slug}`}>
                    {blog?.attributes?.Title?.length > 15
                      ? `${blog?.attributes?.Title.substring(0, 15)}...`
                      : blog?.attributes?.Title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className="sidebar-2">
        <div className="sidebar-item">
          <h2 className="compartilhar">Compartilhar publicação</h2>
          <ul className="social-icons">
            <li>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Compartilhar no LinkedIn" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Compartilhar no Facebook" />
              </a>
            </li>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsapp} alt="Compartilhar no WhatsApp" />
              </a>
            </li>
            <li>
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Compartilhar no Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
