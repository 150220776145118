import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Onepage from "./pages/onepage/index";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./components/Navbar/index";
import Footer from "./components/footer";
import { Route, Switch } from "react-router-dom";
import WhatsAppButton from "./components/button/whatsAppButton";
import BlogTemplate from "./pages/blog/index";
import Slug from "./pages/blog/[slug]";
import { BrowserRouter as Router } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Navbar />
    <Switch>
      <Route path="/" exact component={Onepage} />
      <Route path="/blog" component={BlogTemplate} />
      <Route path="/:slug" component={Slug} />
    </Switch>
    <WhatsAppButton />
    <Footer />
  </Router>
);

reportWebVitals();
