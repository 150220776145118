import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"; 
import "./styles.css";
import "font-awesome/css/font-awesome.min.css";
import config from "../../config";
import { format } from "date-fns";
import Social from "../../components/Social";
import { ArrowBack } from "@styled-icons/boxicons-regular/ArrowBack";

const BlogTemplate = (props) => {
  const [blog, setBlog] = useState(null);
  const history = useHistory(); 

  useEffect(() => {
    const fetchBlogsData = async () => {
      try {
        const reqOptions = {
          headers: {
            Authorization: process.env.REACT_APP_API_TOKEN,
          },
        };
        const response = await fetch(
          `${config.api}/api/blogs?populate=*&filters[slug][$eq]=${props.match.params.slug}`,
          reqOptions
        );
        const data = await response.json();

        if (data && data.data.length > 0) {
          setBlog(data.data[0]);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogsData();
  }, [props.match.params.slug]);

  console.log("alo",props)

  if (!blog) {
    return null;
  }

  return (
    <div className="blog-content">
      <span  className="spanBack" onClick={() => history.goBack()}>
        <ArrowBack size={17}/> Voltar
      </span>
      <div className="blog-container">
        <div className="main-content-wrapper">
          <div>
            <h1>{blog?.attributes?.Title}</h1>
            <h3 className="subtitle">{blog?.attributes?.SubTitle}</h3>
          </div>
          <div>
            <button className="like-button">
              <i className="fa fa-thumbs-up"></i> Curtir
            </button>
            <button className="comment-button">
              <i className="fa fa-comment"></i> Comentar
            </button>
          </div>

          <div className="lineDivision"></div>

          <div className="spanPublish">
            <span>{blog?.attributes?.Category?.data?.attributes?.Category}</span>
            <span id="data-publi">
              Em {format(new Date(blog?.attributes?.createdAt), "dd/MM/yyyy")}
            </span>
          </div>

          <div className="conteudoContainer">
            {blog?.attributes?.Content?.map((paragraph, index) => (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: paragraph.children[0].text }}
              />
            ))}
          </div>
        </div>

        <Social />
      </div>
    </div>
  );
};

export default BlogTemplate;
