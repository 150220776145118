import React, { useState } from "react";
import emailjs from "emailjs-com";
import InputMask from "react-input-mask";
import styles from "./styles";
import { FaWhatsapp } from "react-icons/fa";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validatePhone = (phone) => {
    const regex = /^\([0-9]{2}\)\s[0-9]{5}-[0-9]{4}$/;
    return regex.test(phone);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setError("Por favor, insira um endereço de email válido.");
      return;
    }

    if (!validatePhone(phone)) {
      setError(
        "Por favor, insira um número de telefone válido no formato (00) 00000-0000."
      );
      return;
    }

    const formData = {
      from_name: name + " " + sobrenome,
      message: message,
      phone: phone,
    };

    // Envio do email usando emailjs
    emailjs
      .send(
        "service_zzxaen8",
        "template_x112qff",
        formData,
        "qVzZVVlEaUpvwq9Bx"
      )
      .then(() => {
        setIsEmailSent(true);
        setError(null);
        setTimeout(() => {
          setIsEmailSent(false);
        }, 3000); // Limpa a mensagem de sucesso após 3 segundos
      })
      .catch((error) => {
        setIsEmailSent(false);
        setError(
          "Erro ao enviar o email. Por favor, tente novamente mais tarde."
        );
        setTimeout(() => {
          setError(null);
        }, 3000); // Limpa a mensagem de erro após 3 segundos
      })
      .finally(() => {
        // Limpar os campos após o envio do formulário
        setName("");
        setSobrenome("");
        setEmail("");
        setPhone("");
        setMessage("");
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      {isEmailSent && (
        <div style={styles.successMessage}>
          Email enviado com sucesso! Obrigado pelo contato.
        </div>
      )}
      {error && <div style={styles.errorMessage}>{error}</div>}
      <div style={styles.containerContact}>
        <div>
          <label htmlFor="name" style={styles.labelText}>
            Nome
          </label>
          <input
            style={styles.inputContact}
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="sobrenome" style={styles.labelText}>
            Sobrenome
          </label>
          <input
            style={styles.inputContact}
            type="text"
            id="sobrenome"
            value={sobrenome}
            onChange={(e) => setSobrenome(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="email" style={styles.labelText}>
            Email
          </label>
          <input
            style={styles.inputContact}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="phone" style={styles.labelText}>
            Telefone
          </label>
          <InputMask
            style={styles.inputContact}
            mask="(99) 99999-9999"
            maskChar=" "
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="message" style={styles.labelText}>
            Conte-nos sobre seu caso
          </label>
          <textarea
            style={styles.inputContactLabel}
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
      </div>
      <div style={styles.divbuttonContact}>
        <button type="submit" style={styles.buttonContact}>
          ENTRE EM CONTATO
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
