import React, { createRef } from "react";
import "./styles.css";
import ConteudoMobile from "../../components/ConteudoMobile/index.js";
import ConteudoDesktop from "../../components/ConteudoDesktop/index.js";

class index extends React.Component {
  sectionRef = createRef();

  handleClick = (event) => {
    event.preventDefault();
    if (this.sectionRef.current) {
      window.scrollTo({
        top: this.sectionRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;

    if (isMobile) {
      return (
        <ConteudoMobile
          handleClick={this.handleClick}
          sectionRef={this.sectionRef}
        />
      );
    }

    return (
      <ConteudoDesktop
        handleClick={this.handleClick}
        sectionRef={this.sectionRef}
      />
    );
  }
}

export default index;
