import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "./styles.css";

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open("https://api.whatsapp.com/send?phone=43991723978", "_blank");
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        borderRadius: "50%",
        backgroundColor: "#25d366",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        animation: "pulse 2s infinite",
      }}
      onClick={handleClick}
    >
      <FaWhatsapp style={{ color: "#fff", fontSize: "24px" }} />
    </div>
  );
};

export default WhatsAppButton;
