import React, { useEffect, useState } from "react";
import instagram from "../../assets/instagram.svg";
import whatsapp from "../../assets/whatsapp.svg";
import facebook from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";

const Social = () => {
  return (
    <div className="sidebarContainer">
      <div className="sidebar-2">
        <div className="sidebar-item">
          <h2 className="compartilhar">Compartilhar publicação</h2>
          <ul className="social-icons">
            <li>
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkedin} alt="Compartilhar no LinkedIn" />
              </a>
            </li>
            <li>
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Compartilhar no Facebook" />
              </a>
            </li>
            <li>
              <a
                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                  window.location.href
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsapp} alt="Compartilhar no WhatsApp" />
              </a>
            </li>
            <li>
              <a href={instagram} target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Compartilhar no Instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Social;
