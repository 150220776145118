import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import "font-awesome/css/font-awesome.min.css";
import { format } from "date-fns";
import Sidebar from "../../components/Categorias";

const BlogTemplate = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState(allBlogs);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(3);

  const fetchBlogsData = async (params, setterFunction) => {
    const reqOptions = {
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
      },
    };

    try {
      const request = await fetch(
        `${config.api}/api/blogs?populate=*`,
        reqOptions,
        
      );
      const response = await request.json();
      setterFunction(response.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchBlogsData("", setAllBlogs);
    };

    fetchData();
  }, []);

  const indexOfLastBlog = currentPage * perPage;
  const indexOfFirstBlog = indexOfLastBlog - perPage;
  const currentBlogs = filteredBlogs
    ? filteredBlogs.slice(indexOfFirstBlog, indexOfLastBlog)
    : [];

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <div className="blog-content">
      <div className="blog-container">
        <div className="blog-container-card">
          {currentBlogs.map((blog) => (
            <div className="main-content-wrapper" key={blog?.id}>
              <div>
                <h1>{blog?.attributes?.Title}</h1>
                <h3 className="subtitle">{blog?.attributes?.SubTitle}</h3>
              </div>
              <div className="lineDivision"></div>
              <div className="spanPublish">
                <span>
                  {blog?.attributes?.Category?.data?.attributes?.Category}
                </span>
                <span id="data-publi">
                  Em{" "}
                  {format(new Date(blog?.attributes?.createdAt), "dd/MM/yyyy")}
                </span>
              </div>
              <div className="conteudoContainer">
                {blog?.attributes?.Summary}
                <Link to={`/${blog?.attributes?.slug}`} className="saiba-mais">
                  Saiba mais...
                </Link>
              </div>
            </div>
          ))}
          <div className="pagination">
            {Array.from({
              length: Math.ceil(filteredBlogs.length / perPage),
            }).map((item, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
                disabled={currentPage === index + 1}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>

        <Sidebar
          blogs={allBlogs}
          filteredBlogs={filteredBlogs}
          setFilteredBlogs={setFilteredBlogs}
        />
      </div>
    </div>
  );
};

export default BlogTemplate;
