import React, { useState, useEffect } from "react";
import Logo from "../../assets/logoPe.webp";
import "./styles.css";
import { useLocation, Link } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [isNavbarFixed, setIsNavbarFixed] = useState(
    location.pathname === "/blog" && location.pathname === "/:slug"
  );
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/blog") {
      setIsNavbarFixed(true);
      return;
    }

    const handleScroll = () => {
      if (window.pageYOffset > 60) {
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location]);

  const navbarClass = `navbar ${isNavbarFixed ? "fixed" : ""}`;

  const handleClick = () => {
    setClicked(!clicked);
  };

  const scrollToSection = (sectionId) => {
    if (!sectionId) return;
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <nav className={navbarClass}>
        <Link to="/" className="logo">
          <img src={Logo} alt="Logo da Empresa" />
        </Link>
        <div>
          <ul id="navbar" className={clicked ? "active" : ""}>
            <li>
              <Link to="/" onClick={() => scrollToSection("home")}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSection("atuacao")}>
                ATUAÇÃO
              </Link>
            </li>
            <li>
              <Link to="/" onClick={() => scrollToSection("equipe")}>
                EQUIPE
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={() => scrollToSection(null)}>
                BLOG
              </Link>
            </li>
          </ul>
        </div>

        <Link
          to="/"
          id="btnContato"
          className="btnEntreContato"
          onClick={() => scrollToSection("faleconosco")}
        >
          ENTRE EM CONTATO
        </Link>

        <div id="mobile" onClick={handleClick}>
          <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
